

























import { Component, Vue, Watch } from "vue-property-decorator";

import DividerInfo from "@/settings/components/DividerInfo.vue";
import {
  SegmentDividerType,
  SegmentFileModel,
} from "@/segments/models/SegmentModel";
import SegmentGroupModel from "@/segments/models/SegmentGroupModel";

@Component({
  components: {
    DividerInfo,
  },
})
export default class DividerSegmentView extends Vue {
  applicationId = this.$route.params.id;

  get dividerTypes(): Array<SegmentDividerType> {
    return [SegmentDividerType.REVENUE];
  }

  get dividers(): Record<SegmentDividerType, SegmentGroupModel> {
    return this.$store.state.segmentStore.dividers;
  }

  get dividerFiles(): Record<SegmentDividerType, SegmentFileModel> {
    return this.$store.state.segmentStore.dividerFiles;
  }

  @Watch("applicationId", { immediate: true })
  watchApplicationId(applicationId: string) {
    this.$store.dispatch("getDividers", applicationId);
  }

  created() {
    document.title = this.$lang(
      "documentTitle",
      this.$lang("segment.divider.title")
    );
  }
}
