




import { Component, Ref, Vue, Prop, Watch } from "vue-property-decorator";
import * as ace from "brace";
import "brace/theme/tomorrow";
import "brace/theme/tomorrow_night";

@Component
export default class FileEditor extends Vue {
  @Prop() value!: string;
  @Prop() mode?: string;
  @Prop({ default: false }) loading!: boolean;
  @Prop({ default: false }) readonly!: boolean;

  @Ref("el") readonly elContainer!: HTMLElement;

  private csvView!: ace.Editor;

  get dark() {
    return this.$vuetify.theme.dark;
  }

  mounted() {
    const editor = ace.edit(this.elContainer);
    editor.setTheme(
      this.dark ? "ace/theme/tomorrow_night" : "ace/theme/tomorrow"
    );
    if (this.mode) {
      editor.getSession().setMode(this.mode);
    }
    editor.setReadOnly(this.readonly);
    editor.setValue(this.value || "");
    this.csvView = editor;
  }

  @Watch("value", { deep: true })
  private watchValue() {
    this.csvView.setValue(this.value || "");
  }

  @Watch("dark")
  private watchDark(dark: boolean) {
    if (!this.csvView) return;

    this.csvView.setTheme(
      dark ? "ace/theme/tomorrow_night" : "ace/theme/tomorrow"
    );
  }
}
