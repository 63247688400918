var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"md":"11"}},[_c('v-file-input',{attrs:{"label":_vm.$lang(("segment.divider." + (_vm.type.toString().toLowerCase()))),"truncate-length":50,"clearable":false,"accept":"text/csv"},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}})],1),_c('v-col',{attrs:{"md":"1"}},[_c('v-btn',{staticClass:"mt-8 mx-4",attrs:{"disabled":!_vm.value.id,"icon":"","x-small":""},on:{"click":function($event){{
            _vm.downloadFile();
            _vm.viewDialog = true;
          }}}},[_c('v-icon',[_vm._v(" visibility ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.uploadDialog),callback:function ($$v) {_vm.uploadDialog=$$v},expression:"uploadDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$lang("segment.divider.upload.title"))+" ")]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$lang("segment.divider.upload.message"))+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){{
              _vm.cancelUpload();
              _vm.uploadDialog = false;
            }}}},[_vm._v(" "+_vm._s(_vm.$lang("btn.back"))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){{
              _vm.uploadFile(_vm.file);
              _vm.uploadDialog = false;
            }}}},[_vm._v(" "+_vm._s(_vm.$lang("btn.upload"))+" ")])],1)],1)],1),_c('v-dialog',{model:{value:(_vm.viewDialog),callback:function ($$v) {_vm.viewDialog=$$v},expression:"viewDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$lang("segment.divider.view.title"))+" ")]),_c('v-card-text',[_c('file-editor',{attrs:{"loading":_vm.$store.state.segmentStore.loadingDividerFile,"readonly":true},model:{value:(_vm.fileInfo.content),callback:function ($$v) {_vm.$set(_vm.fileInfo, "content", $$v)},expression:"fileInfo.content"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.viewDialog = false}}},[_vm._v(" "+_vm._s(_vm.$lang("btn.back"))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }