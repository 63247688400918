
























































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import FileEditor from "@/shared/components/FileEditor.vue";
import {
  SegmentFileModel,
  SegmentDividerType,
} from "@/segments/models/SegmentModel";
import SegmentGroupModel from "@/segments/models/SegmentGroupModel";
import {
  UploadDividerRequestModel,
  GetDividerFileRequestModel,
} from "@/segments/models/SegmentGroupRequestModel";

@Component({
  components: {
    FileEditor,
  },
})
export default class DividerInfo extends Vue {
  @Prop() applicationId!: string;
  @Prop() type!: SegmentDividerType;
  @Prop() value!: SegmentGroupModel;
  @Prop() fileInfo!: SegmentFileModel;

  file: File | null = null;
  uploadDialog = false;
  viewDialog = false;

  @Watch("value", { deep: true, immediate: true })
  private watchValue() {
    this.file = this.value.sourceFileName
      ? new File([], this.value.sourceFileName)
      : null;
  }

  @Watch("file", { deep: true })
  private watchFile(file?: File) {
    this.uploadDialog = !!file && file.size !== 0;
  }

  uploadFile(file?: File) {
    if (file && file.size !== 0)
      this.$store.dispatch(
        "uploadDivider",
        new UploadDividerRequestModel(this.applicationId, this.type, file)
      );
  }

  downloadFile() {
    if (!this.fileInfo.content) {
      this.$store.dispatch(
        "getDividerFile",
        new GetDividerFileRequestModel(this.applicationId, this.type)
      );
    }
  }

  cancelUpload() {
    this.file = this.value.sourceFileName
      ? new File([], this.value.sourceFileName)
      : null;
  }
}
